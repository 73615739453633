<template lang="">
  <div class="card dveb-white-card tab-card">
    <b-tabs content-class="mt-3">
      <b-tab
        content-class="tab-content"
        title="İl"
        active
        @click="changeTab('il')"
        ><County v-if="activeTab === 'il'"
      /></b-tab>
      <b-tab title="İlçe" @click="changeTab('ilce')"
        ><District v-if="activeTab === 'ilce'"
      /></b-tab>
      <b-tab title="Mahalle / Köy" @click="changeTab('mahalle')"
        ><Street v-if="activeTab === 'mahalle'"
      /></b-tab>
    </b-tabs>

    
  </div>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  data() {
    return {
      activeTab: "il",
    };
  },
  components: {
    County: () => import("@/components/manage/location/list/County"),
    District: () => import("@/components/manage/location/list/District"),
    Street: () => import("@/components/manage/location/list/Street"),
  },
  methods: {
    changeTab(tabName) {
      this.activeTab = tabName;
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "İl - İlçe - Mahallesi Listesi", route: "/manage/location" },
    ]);
  },
};
</script>
<style lang=""></style>
